// Lib
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
// Api
import {
  useCreateCouponMutation,
  useGetCouponQuery,
  useUpdateCouponMutation,
} from "rtkQuery/query/couponsAPI";
// Hooks
import { useNotification, usePermissions } from "hooks";
// Types
import { CouponAllowedUser, CouponType, DiscountType } from "types/coupons";
import { OrderPaymentType } from "types/orders";
import {
  TopUpCouponForm,
  TabKey,
  UsersType,
  UseType,
  TopUpType,
} from "../../../types";
// Constants
import { ADMIN_ROUTES, NOTIFICATIONS, rtkQueryParams } from "consts";
// Helpers
import {
  transformTopUpCouponsFormToRequestData,
  transformToTopUpFormData,
} from "../../../helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { PencilIcon } from "icons";
// Components
import { Skeleton } from "antd";
import { ArrowBackLink, Tabs } from "components";
import { TabLabel, UsersTab } from "../../../components";
import { MainTab } from "../components";
// Styled
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";
import {
  FlexContainer,
  PageHeadingContainer,
  PageTitleContainer,
  PageWrapper,
} from "styled/Box";

import { resolver } from "./validation";
import { useGetExchangeRateQuery } from "rtkQuery/query/exchangeRateAPI";

export const TopUpCouponEdit: FC = () => {
  const navigate = useNavigate();
  const { canCouponsUpdate } = usePermissions();
  const { openNotification } = useNotification();
  const { id } = useParams();

  const isEdit = !!id;

  const [create] = useCreateCouponMutation();
  const [update] = useUpdateCouponMutation();

  const { data: exchangeRate, error: exchangeRateError } =
    useGetExchangeRateQuery(null, rtkQueryParams);

  const {
    data,
    isFetching: isCouponGetLoading,
    error,
  } = useGetCouponQuery({ id }, { ...rtkQueryParams, skip: !id });

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  useEffect(() => {
    if (exchangeRateError) {
      errorHandler({ error: exchangeRateError, openNotification });
    }
  }, [exchangeRateError]);

  const [isEditMode, setEditMode] = useState(() => (!isEdit ? true : false));
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<TabKey>(TabKey.Main);
  const [useType, setUseType] = useState<UseType | null>(() =>
    isEdit ? null : UseType.OneTime,
  );
  const [usersType, setUsersType] = useState<UsersType | null>(() =>
    isEdit ? null : UsersType.All,
  );
  const [topUpType, setTopUpType] = useState<TopUpType | null>(() =>
    isEdit ? null : TopUpType.WithoutMimimunTopUp,
  );

  const [calculatedCoinsAmount, setCalculatedCoinsAmount] = useState<number>(0);
  const [allowedUsers, setAllowedUser] = useState<CouponAllowedUser[]>([]);
  const [isAllowedUsersError, setIsAllowedUsersError] = useState(false);
  const [isCouponNameDisabled, setIsCouponNameDisabled] = useState(false);

  const { handleSubmit, control, reset, setValue, watch } =
    useForm<TopUpCouponForm>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      resolver,
      defaultValues: {
        allowMultipleUsage: false,
        isActive: false,
        numberOfUses: 1,
        numberOfUniqueUses: undefined,
        minimumOrderFiatCentsPrice: 0,
        minimumOrderFmcCentsPrice: 0,
        discountType: DiscountType.Percentage,
        couponType: CouponType.DiscountCode,
        orderPaymentType: OrderPaymentType.FiatCents,
        name: "",
        numberOfCouponsToGenerate: 1,
      },
    });

  const discountType = watch("discountType");
  const orderPaymentType = watch("orderPaymentType");
  const couponName = watch("name");
  const numberOfCouponsToGenerate = watch("numberOfCouponsToGenerate");
  const discountOff = watch("discountOff");

  useEffect(() => {
    if (!isEditMode) {
      return;
    }

    if (discountType !== DiscountType.FmcCents) {
      return;
    }

    const coinsAmount =
      (discountOff * ((exchangeRate?.value || 0) * 1000)) / 1000;

    setCalculatedCoinsAmount(coinsAmount);
  }, [discountOff]);

  useEffect(() => {
    if (numberOfCouponsToGenerate > 1) {
      if (couponName) {
        setValue("name", "");
        openNotification({
          type: "warning",
          message: "The Сoupon Сodes will be randomly generated",
        });
      }

      setIsCouponNameDisabled(true);
    } else {
      setIsCouponNameDisabled(false);
    }
  }, [numberOfCouponsToGenerate]);

  const handleResetForm = () => {
    if (!data) {
      return;
    }

    if (data.numberOfUses > 1) {
      setUseType(UseType.Multiple);
    } else {
      setUseType(UseType.OneTime);
    }

    if (data?.allowedUsers?.length) {
      setAllowedUser(data?.allowedUsers || []);
      setUsersType(UsersType.List);
    } else if (data?.numberOfUniqueUses) {
      setAllowedUser([]);
      setUsersType(UsersType.UniqueUsers);
    } else {
      setAllowedUser([]);
      setUsersType(UsersType.All);
    }

    if (data?.discountFiatCents) {
      setCalculatedCoinsAmount(data?.discountOff);
    }

    if (data?.minimumOrderFiatCentsPrice || data?.minimumOrderFmcCentsPrice) {
      setTopUpType(TopUpType.MinimumTopUp);
    } else {
      setTopUpType(TopUpType.WithoutMimimunTopUp);
    }

    reset(transformToTopUpFormData(data));
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    handleResetForm();
  }, [data]);

  const handleAddCustomers = (customers: CouponAllowedUser[]) => {
    if (isAllowedUsersError) {
      setIsAllowedUsersError(false);
    }
    setAllowedUser(customers);
  };

  const handleSetUseType = (value: UseType) => {
    if (value === UseType.OneTime) {
      setValue("numberOfUses", 1);
      setValue("allowMultipleUsage", false);
    } else {
      setValue("numberOfUses", 2);
      setValue("allowMultipleUsage", true);
    }
    setUseType(value);
  };

  const handleSetUsersType = (value: UsersType) => {
    setUsersType(value);

    if (value === UsersType.All) {
      setIsAllowedUsersError(false);
    }
  };

  const handleSetTopUpType = (value: TopUpType) => {
    if (value === TopUpType.WithoutMimimunTopUp) {
      setValue("minimumOrderFiatCentsPrice", 0);
      setValue("minimumOrderFmcCentsPrice", 0);
    }
    setTopUpType(value);
  };

  const handleSetDiscountType = (value: DiscountType) => {
    switch (value) {
      case DiscountType.FmcCents:
        setTopUpType(TopUpType.WithoutTopUp);
        setValue("discountType", value);
        setValue("couponType", CouponType.GiftVoucher);
        setValue("orderPaymentType", OrderPaymentType.FmcCents);

        return;

      case DiscountType.Percentage:
        setUsersType(UsersType.All);
        setTopUpType(TopUpType.WithoutMimimunTopUp);
        setValue("discountType", value);
        setValue("couponType", CouponType.DiscountCode);
        setValue("orderPaymentType", OrderPaymentType.FiatCents);

        return;

      default:
        return;
    }
  };

  const name = isEdit ? data?.couponCode : "New Coupon";

  const isLoading = isCouponGetLoading || isEditLoading;

  const items = [
    {
      key: TabKey.Main,
      label: <TabLabel label={TabKey.Main} />,
      children: (
        <MainTab
          isEdit={isEdit}
          isEditMode={isEditMode}
          isCouponNameDisabled={isCouponNameDisabled}
          isLoading={isLoading}
          calculatedCoinsAmount={calculatedCoinsAmount}
          control={control}
          useType={useType}
          usersType={usersType}
          topUpType={topUpType}
          discountType={discountType}
          orderPaymentType={orderPaymentType}
          handleSetUseType={handleSetUseType}
          handleSetUsersType={handleSetUsersType}
          handleSetDiscountType={handleSetDiscountType}
          handleSetTopUpType={handleSetTopUpType}
        />
      ),
    },
    usersType === UsersType.List && {
      key: TabKey.Users,
      label: <TabLabel label={TabKey.Users} isError={isAllowedUsersError} />,
      children: (
        <UsersTab
          isEditMode={isEditMode}
          isLoading={isLoading}
          allowedUsers={allowedUsers}
          handleAddCustomers={handleAddCustomers}
        />
      ),
    },
  ];

  const onSubmit: SubmitHandler<TopUpCouponForm> = async data => {
    if (usersType === UsersType.List) {
      if (allowedUsers.length === 0) {
        setIsAllowedUsersError(true);
        return;
      }
    }

    try {
      setIsEditLoading(true);
      if (isEdit) {
        await update({
          id,
          data: transformTopUpCouponsFormToRequestData(
            data,
            usersType,
            useType,
            allowedUsers,
            discountType,
            exchangeRate?.value,
            calculatedCoinsAmount,
          ),
        }).unwrap();

        openNotification({ message: NOTIFICATIONS.COUPON_UPDATED });

        setEditMode(false);
      } else {
        const response = await create(
          transformTopUpCouponsFormToRequestData(
            data,
            usersType,
            useType,
            allowedUsers,
            discountType,
            exchangeRate?.value,
            calculatedCoinsAmount,
          ),
        ).unwrap();

        openNotification({ message: NOTIFICATIONS.COUPON_CREATED });

        setEditMode(false);

        if (response?.length > 1) {
          navigate(`${ADMIN_ROUTES.TOP_UP_COUPONS.path}`);
        } else {
          navigate(`${ADMIN_ROUTES.TOP_UP_COUPONS.path}/${response[0].id}`);
        }
      }
    } catch (error) {
      errorHandler({ error, openNotification });
    } finally {
      setIsEditLoading(false);
    }
  };

  const onCancel = () => {
    setEditMode(false);
    handleResetForm();
  };

  return (
    <PageWrapper $fullwidth $column>
      <FlexContainer
        $fullwidth
        $column
        $gap={24}
        $grow={1}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <PageHeadingContainer>
          <PageTitleContainer $column>
            <ArrowBackLink
              title="Back to Top-up Coupons"
              path={ADMIN_ROUTES.TOP_UP_COUPONS.path}
            />

            <FlexContainer
              $gap={12}
              $align="center"
              $justify="center"
              $margin="20px 0 0 0"
            >
              {isCouponGetLoading ? (
                <>
                  <Skeleton.Input active size={"default"} />
                </>
              ) : name ? (
                <>
                  <Typography.H1>{name}</Typography.H1>
                </>
              ) : null}
            </FlexContainer>
          </PageTitleContainer>

          {canCouponsUpdate && (
            <FlexContainer $gap={8}>
              {!isEditMode ? (
                <Button.Heading
                  icon={<PencilIcon />}
                  onClick={e => {
                    e.preventDefault();
                    setEditMode(true);
                  }}
                >
                  Edit
                </Button.Heading>
              ) : (
                <>
                  <Button.Heading
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Save
                  </Button.Heading>
                  {isEdit && (
                    <Button.Heading onClick={onCancel}>Cancel</Button.Heading>
                  )}
                </>
              )}
            </FlexContainer>
          )}
        </PageHeadingContainer>

        <Tabs
          activeKey={activeTab}
          items={items}
          onChange={(activeKey: TabKey) => setActiveTab(activeKey)}
        />
      </FlexContainer>
    </PageWrapper>
  );
};
